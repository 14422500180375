import { combineReducers } from "redux";
import authReducer from "./authReducer";
import visitorReducer from "./visitorReducer";
import accountReducer from "./accountReducer";
import snackbar from "./snackbar";

export default combineReducers({
  auth: authReducer,
  visitor: visitorReducer,
  account: accountReducer,
  snackbar: snackbar,
});

// const rootReducer = (state, action) => {
//   if (action.type === "USER_LOGOUT") {
//     state = undefined;
//   }
//   return appReducer(state, action);
// };

// export default rootReducer;
