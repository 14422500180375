import { updateObject } from "./utility";

const init = {
  listVisitor: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  loadingUserList: false,
  messageVisitorList: "",
  successVisitorList: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_VISITOR_LIST_SUCCESS":
      return updateObject(state, {
        listVisitor: action.payload,
        successVisitorList: true,
        loadingVisitorList: false,
      });
    case "GET_VISITOR_LIST_FAILED":
      return updateObject(state, {
        successVisitorList: false,
        loadingVisitorList: false,
        messageVisitorList: action.payload,
        listVisitor: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });

    default:
      return state;
  }
}
