import { updateObject } from "./utility";
import { initialState } from "../initialState";

const init = {
  profile: [],
  changePassword: [],
  detailMerchant: [],
  successGetDetailMerchant: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "LOGIN":
      return updateObject(state, {
        profile: action.payload,
      });
    case "CHANGE_PASSWORD":
      return updateObject(state, {
        changePassword: action.payload,
      });
    case "GET_DETAIL_MERCHANT_SUCCESS":
      return updateObject(state, {
        detailMerchant: action.payload,
        successGetDetailMerchant: true,
      });
    case "GET_DETAIL_MERCHANT_FAILED":
      return updateObject(state, {
        detailMerchant: [],
        successGetDetailMerchant: false,
      });
    default:
      return state;
  }
}
