import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getCookie } from "../utils/cookie";

function PublicRoute({ component: Component, ...rest }) {
  // const isLogin = getCookie("token-wallet-webmerchant");
  const isLogin = localStorage.getItem("token-wallet-webmerchant");
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogin ? <Component {...props} /> : <Redirect to="/visit-data" />
      }
    />
  );
}

export default PublicRoute;
