import { updateObject } from "./utility";

const init = {
  listDataUserAll: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
  },
  loadingAccount: false,
  messageAccount: "",
  successAddUser: false,
  loadingGetAll: false,
  successGetAll: false,
  messageGetAll: "",
  page: 1,
};

export default function (state = init, action) {
  switch (action.type) {
    case "ADD_USER":
      return updateObject(state, {
        successAddUser: true,
      });
    case "GET_ALL_USER_MERCHANT_REQUEST":
      return updateObject(state, {
        loadingGetAll: true,
      });
    case "GET_ALL_USER_MERCHANT_SUCCESS":
      return updateObject(state, {
        loadingGetAll: false,
        successGetAll: true,
        listDataUserAll: action.payload,
      });
    case "GET_ALL_USER_MERCHANT_FAILED":
      return updateObject(state, {
        loadingGetAll: false,
        successGetAll: false,
        messageGetAll: action.payload,
      });
    case "SET_PAGE":
      return updateObject(state, {
        page: action.payload,
      });
    default:
      return state;
  }
}
