import React, { Component, Suspense, lazy } from "react";
import { Redirect } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import PublicRoute from "./components/PublicRoute";
import Loading from "./components/Loading";

const Login = lazy(() => import("./views/Pages/Login"));
const Layout = lazy(() => import("./containers/Layout"));

function App() {
  // const Loading = () => (
  //   <div className="d-flex  align-items justify-content-center text-center">
  //     Loading...
  //   </div>
  // );

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <PublicRoute name="Login" path="/login" component={Login} />
          <Route
            path="/"
            name="Home"
            render={(props) => <Layout {...props} />}
          />

          <Redirect from="/" to="/login" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
